import logo from './logo.svg';
import chatgpt from './chatgpt.png';
import './App.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faEtsy,
  faFacebook,
  faInstagram, faLinkedin,
  faPinterest,
  faTiktok,
  faTwitter,
  faXTwitter,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"/>
        <p style={{fontSize: "50px"}}>
          Stay tuned, coming soon!
        </p>
        <p>
          Welcome to the magical world of Cuckoo Kitchen, where food pairing meets artificial intelligence<br/> to
          offer you extraordinary recipes and much more!<br/><br/>
        </p>
        <p style={{fontSize: "14px"}}>
          In the meantime...
          <a className="App-link"
             href="https://chatgpt.com/g/g-FDauaPnt4-cuckookitchen-ai"
             target="_blank"
             style={{fontWeight: 900}}
          >
            try our cuckookitchen.ai GPTs
          </a>
          and follow us on<br/><br/>
        </p>
        <div style={{display: "inline-block", fontSize: "30px"}}>
          <a
              className="App-link"
              href="https://chatgpt.com/g/g-FDauaPnt4-cuckookitchen-ai"
              target="_blank"
              rel="noopener noreferrer"
              style={{display: "inline-block"}}
          >
            <img src={chatgpt} width={30}/>
            <div style={{fontSize: "12px"}}>ChatGPT</div>
          </a>
          <a
              className="App-link"
              href="https://www.youtube.com/@cuckookitchen"
              target="_blank"
              rel="noopener noreferrer"
              style={{display: "inline-block"}}
          >
            <FontAwesomeIcon icon={faYoutube}/>
            <div style={{fontSize: "12px"}}>YouTube</div>
          </a>
          <a
              className="App-link"
              href="https://www.tiktok.com/@cuckookitchen"
              target="_blank"
              rel="noopener noreferrer"
              style={{display: "inline-block"}}
          >
            <FontAwesomeIcon icon={faTiktok}/>
            <div style={{fontSize: "12px"}}>TikTok</div>
          </a>
          <a
              className="App-link"
              href="https://www.instagram.com/cuckookitchenai"
              target="_blank"
              rel="noopener noreferrer"
              style={{display: "inline-block"}}
          >
            <FontAwesomeIcon icon={faInstagram}/>
            <div style={{fontSize: "12px"}}>Instagram</div>
          </a>
          <a
              className="App-link"
              href="https://www.facebook.com/cuckookitchenai"
              target="_blank"
              rel="noopener noreferrer"
              style={{display: "inline-block"}}
          >
            <FontAwesomeIcon icon={faFacebook}/>
            <div style={{fontSize: "12px"}}>Facebook</div>
          </a>
          <a
              className="App-link"
              href="https://x.com/cuckookitchenai"
              target="_blank"
              rel="noopener noreferrer"
              style={{display: "inline-block"}}
          >
            <FontAwesomeIcon icon={faXTwitter}/>
            <div style={{fontSize: "12px"}}>Twitter (X)</div>
          </a>
          <a
              className="App-link"
              href="https://ch.pinterest.com/cuckookitchenai"
              target="_blank"
              rel="noopener noreferrer"
              style={{display: "inline-block"}}
          >
            <FontAwesomeIcon icon={faPinterest}/>
            <div style={{fontSize: "12px"}}>Pinterest</div>
          </a>
          <a
              className="App-link"
              href="https://www.linkedin.com/in/media-cuckookitchen-04012926b/"
              target="_blank"
              rel="noopener noreferrer"
              style={{display: "inline-block"}}
          >
            <FontAwesomeIcon icon={faLinkedin}/>
            <div style={{fontSize: "12px"}}>Linkedin</div>
          </a>
          <a
              className="App-link"
              href="https://www.etsy.com/shop/CuckooKitchen"
              target="_blank"
              rel="noopener noreferrer"
              style={{display: "inline-block"}}
          >
            <FontAwesomeIcon icon={faEtsy}/>
            <div style={{fontSize: "12px"}}>Etsy</div>
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
